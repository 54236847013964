import "./styles.css";
import Dress from "./components/Dress";

export default function DressesList({ dresses }) {
  return dresses.length ? (
    <ul className="DressesListContainer">
      {dresses.map((dress) => (
        <Dress key={dress.id} {...dress} />
      ))}
    </ul>
  ) : (
    <p>Ooops, no dresses found for the selected filters...</p>
  );
}
