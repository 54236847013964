const data = [
  {
    id: "modelo-82",
    name: "galilea",
    description: "Purple Satin Two pieces Halter Cutout Maxi dress",
    price: 70,
    type: "maxi dress",
    tags: ["daytime", "cocktail", "beach"],
    material: "satin",
    size: "small",
    images: [
      "galilea-main.jpg",
      "galilea-1.jpg",
      "galilea-3.jpg",
      "galilea-2.jpg",
    ],
    color: "purple",
  },
  {
    id: "modelo-109",
    name: "dalia",
    description: "Hot pink Satin Ruched Cutout Maxi dress",
    price: 70,
    type: "maxi dress",
    tags: ["daytime", "cocktail", "beach"],
    material: "satin",
    size: "small",
    images: ["dalia-main.jpg", "dalia-2.jpg", "dalia-1.jpg"],
    color: "pink",
  },
  {
    id: "modelo-19",
    name: "maria",
    description: "Copper Satin Long sleeves Bustier Maxi dress",
    price: 70,
    type: "maxi dress",
    tags: ["daytime", "cocktail", "evening"],
    material: "satin",
    size: "small",
    images: ["maria-3.jpg", "maria-2.jpg", "maria-1.jpg", "maria-main.jpg"],
    color: "orange",
  },
  {
    id: "modelo-40",
    name: "tulum",
    description: "Lime Green One Shoulder Long Wide sleeve Mini dress",
    price: 50,
    type: "mini dress",
    tags: ["daytime", "cocktail", "beach"],
    material: "satin",
    size: "small",
    color: "green",
    images: ["tulum-1.jpg", "tulum-2.jpg", "tulum-main.jpg"],
  },
  {
    id: "modelo-81",
    name: "condesa",
    description:
      "Blue Satin Overlap collar Draped side ruched Long sleeve Mini dress",
    price: 50,
    type: "mini dress",
    tags: ["daytime", "cocktail", "beach", "evening"],
    material: "satin",
    size: "small",
    images: ["condesa-main.jpg", "condesa-1.jpg", "condesa-2.jpg"],
    color: "blue",
  },
  {
    id: "modelo-97",
    name: "rosal",
    description: "Red Velvet Bustier Maxi dress",
    price: 70,
    type: "maxi dress",
    tags: ["daytime", "cocktail", "evening"],
    material: "velvet",
    size: "small",
    images: ["rosal-main.jpg", "rosal-3.jpg", "rosal-2.jpg", "rosal-1.jpg"],
    color: "red",
  },
  {
    id: "modelo-94",
    name: "gladis",
    description: "Green Lycra One shoulder Long Sleeve Cutout Maxi dress",
    price: 70,
    type: "maxi dress",
    tags: ["daytime", "cocktail", "beach"],
    material: "lycra",
    size: "small",
    color: "green",
    images: ["gladis-main.jpg", "gladis-1.jpg"],
  },
  {
    id: "modelo-87",
    name: "tulipan",
    description: "White V-Neck Draped side ruched Maxi dress",
    price: 70,
    type: "",
    tags: ["daytime", "cocktail", "beach"],
    material: "",
    size: "small",
    color: "white",
    images: ["tulipan-main.jpg", "tulipan-1.jpg", "tulipan-2.jpg"],
  },
  {
    id: "modelo-27",
    name: "lucero",
    description: "Electric blue Flamenco flounce Deep Neckline Maxi dress",
    price: 70,
    type: "maxi dress",
    tags: ["daytime", "cocktail"],
    material: "",
    size: "small",
    color: "blue",
    images: [
      "lucero-1.jpg",
      "lucero-3.jpg",
      "lucero-2.jpg",
      "lucero-4.jpg",
      "lucero-main.jpg",
    ],
  },
  {
    id: "modelo-101",
    name: "lirio",
    description: "Rose Gold Sequins Sleeveless Maxi dress",
    price: 75,
    type: "maxi dress",
    tags: ["daytime", "cocktail", "evening"],
    material: "sequins",
    size: "small",
    color: "pink",
    images: ["lirio-main.jpg", "lirio-2.jpg", "lirio-1.jpg"],
  },
  {
    id: "modelo-67",
    name: "lila",
    description: "Lilac Back Criss Cross tie Cutout Maxi dress",
    price: 70,
    type: "maxi dress",
    tags: ["daytime", "cocktail"],
    material: "",
    size: "small",
    color: "lilac",
    images: ["lila-3.jpg", "lila-2.jpg", "lila-1.jpg", "lila-main.jpg"],
  },
  {
    id: "modelo-88",
    name: "puebla",
    description: "Magenta Deep V-Neck Wide skirt Maxi dress",
    price: 70,
    type: "maxi dress",
    tags: ["daytime", "cocktail", "evening"],
    material: "",
    size: "small",
    color: "purple",
    images: ["puebla-main.jpg", "puebla-1.jpg", "puebla-2.jpg", "puebla-3.jpg"],
  },
  {
    id: "modelo-201",
    name: "malva",
    description: "Amaranth Shiffon Sleeveless Front Knot Maxi dress",
    price: 70,
    type: "maxi dress",
    tags: ["daytime", "cocktail", "beach"],
    material: "shiffon",
    size: "small",
    color: "purple",
    images: ["malva-1.jpg", "malva-2.jpg", "malva-main.jpg"],
  },
  {
    id: "modelo-206",
    name: "julieta",
    description: "Black Lace Halter Neck Sheer Lace skirt Backless Maxi dress",
    price: 70,
    type: "maxi dress",
    tags: ["evening", "beach"],
    material: "lace",
    size: "small",
    color: "black",
    images: ["julieta-2.jpg", "julieta-1.jpg", "julieta-main.jpg"],
  },
  {
    id: "modelo-207",
    name: "amalia",
    description:
      "Dark Blue Tropical print Off the Shoulder Sheer Skirt Maxi dress",
    price: 70,
    type: "maxi dress",
    tags: ["evening"],
    material: "lace",
    size: "small",
    color: "blue",
    images: ["amalia-1.jpg", "amalia-2.jpg", "amalia-main.jpg", "amalia-3.jpg"],
  },
  {
    id: "modelo-61",
    name: "taxco",
    description: "Silver Tulle and Sequins Maxi dress",
    price: 75,
    type: "maxi dress",
    tags: ["formal"],
    material: "sequins",
    size: "medium",
    color: "silver",
    images: ["taxco-3.jpg", "taxco-2.jpg", "taxco-1.jpg", "taxco-main.jpg"],
  },
  {
    id: "modelo-12",
    name: "felix",
    description: "Yellow Satin Cutout Ruched ruffle trim Maxi dress",
    price: 70,
    type: "maxi dress",
    tags: ["daytime", "cocktail", "beach"],
    material: "satin",
    size: "medium",
    color: "yellow",
    images: ["felix-3.jpg", "felix-2.jpg", "felix-1.jpg", "felix-main.jpg"],
  },
  {
    id: "modelo-42",
    name: "peonia",
    description: "Pink Blush Satin Cutout Knotted Maxi dress",
    price: 70,
    type: "maxi dress",
    tags: ["daytime", "cocktail", "beach", "evening"],
    material: "satin",
    size: "medium",
    color: "pink",
    images: ["peonia-main.jpg", "peonia-1.jpg", "peonia-2.jpg"],
  },
  {
    id: "modelo-102",
    name: "amapola",
    description: "Salmon Satin One shoulder Front Knot wrap Midi dress",
    price: 60,
    type: "midi dress",
    tags: ["daytime", "cocktail", "beach", "evening"],
    material: "satin",
    size: "medium",
    color: "orange",
    images: [
      "amapola-3.jpg",
      "amapola-2.jpg",
      "amapola-1.jpg",
      "amapola-main.jpg",
    ],
  },
  {
    id: "modelo-64",
    name: "leona",
    description: "Pale Orange Satin Sleeveles Cowl neck Maxi dress",
    price: 70,
    type: "maxi dress",
    tags: ["daytime", "cocktail"],
    material: "satin",
    size: "medium",
    color: "orange",
    images: ["leona-1.jpg", "leona-2.jpg", "leona-main.jpg"],
  },
  {
    id: "modelo-112",
    name: "merida",
    description:
      "Green Pear Satin Cutout One shoulder Long sleeve Flamenco flounce Maxi dress",
    price: 70,
    type: "maxi dress",
    tags: ["daytime", "cocktail", "evening"],
    material: "satin",
    size: "medium",
    color: "green",
    images: ["merida-main.jpg", "merida-1.jpg", "merida-2.jpg"],
  },
  {
    id: "modelo-54",
    name: "petunia",
    description: "Bubblegum pink Sleeveless Skirt flounce Maxi dress",
    price: 70,
    type: "maxi dress",
    tags: ["daytime", "cocktail", "beach"],
    material: "",
    size: "medium",
    color: "pink",
    images: ["petunia-main.jpg", "petunia-1.jpg"],
  },
  {
    id: "modelo-66",
    name: "elena",
    description:
      "Electric Purple Satin One shoulder Front Knot wrap Backless Midi dress",
    price: 60,
    type: "midi dress",
    tags: ["daytime", "cocktail", "beach", "evening"],
    material: "satin",
    size: "medium",
    color: "purple",
    images: ["elena-main.jpg", "elena-2.jpg", "elena-1.jpg"],
  },
  {
    id: "modelo-57",
    name: "dolores",
    description: "Black Satin Sleeveles Cowl neck Maxi dress",
    price: 70,
    type: "maxi dress",
    tags: ["evening", "cocktail", "beach"],
    material: "satin",
    size: "medium",
    color: "black",
    images: ["dolores-main.jpg", "dolores-1.jpg", "dolores-2.jpg"],
  },
  {
    id: "modelo-4",
    name: "cielo",
    description: "Baby Blue Satin One shoulder Draped side Midi dress",
    price: 60,
    type: "midi dress",
    tags: ["daytime", "cocktail", "beach"],
    material: "satin",
    size: "medium",
    color: "blue",
    images: ["cielo-main.jpg", "cielo-2.jpg", "cielo-1.jpg"],
  },
  {
    id: "modelo-41",
    name: "olivo",
    description: "Mint green Satin One shoulder Draped side Knotted Maxi dress",
    price: 70,
    type: "maxi dress",
    tags: ["daytime", "cocktail", "beach", "evening"],
    material: "satin",
    size: "medium",
    color: "green",
    images: ["olivo-main.jpg", "olivo-1.jpg", "olivo-2.jpg"],
  },
  {
    id: "modelo-74",
    name: "coyoacan",
    description: "Deep Orange Satin Halter Backless Maxi dress",
    price: 70,
    type: "maxi dress",
    tags: ["daytime", "cocktail", "beach"],
    material: "satin",
    size: "medium",
    color: "orange",
    images: [
      "coyoacan-1.jpg",
      "coyoacan-2.jpg",
      "coyoacan-3.jpg",
      "coyoacan-main.jpg",
    ],
  },
  {
    id: "modelo-51",
    name: "aloe",
    description: "Electric Green Satin Cowl Neck Criss Cross Chain Maxi dress",
    price: 70,
    type: "maxi dress",
    tags: ["daytime", "cocktail", "beach"],
    material: "satin",
    size: "medium",
    color: "green",
    images: ["aloe-1.jpg", "aloe-2.jpg", "aloe-3.jpg", "aloe-main.jpg"],
  },
  {
    id: "modelo-62",
    name: "loreto",
    description: "Lilac Satin Draped One shoulder Knotted Side Midi dress",
    price: 60,
    type: "midi dress",
    tags: ["daytime", "cocktail", "evening"],
    material: "satin",
    size: "medium",
    color: "lilac",
    images: ["loreto-2.jpg", "loreto-1.jpg", "loreto-main.jpg"],
  },
  {
    id: "modelo-53",
    name: "rosarito",
    description: "Wine Red Satin Crossed Top Backless Draped Side Maxi dress",
    price: 70,
    type: "maxi dress",
    tags: ["evening", "cocktail", "beach"],
    material: "satin",
    size: "medium",
    images: ["rosarito-2.jpg", "rosarito-1.jpg", "rosarito-main.jpg"],
  },
  {
    id: "modelo-34",
    name: "josefa",
    description: "Emerald green Satin One long sleeve Maxi dress",
    price: 70,
    type: "maxi dress",
    tags: ["daytime", "cocktail", "evening"],
    material: "satin",
    size: "medium",
    color: "green",
    images: ["josefa-1.jpg", "josefa-main.jpg", "josefa-catalog.jpg"],
  },
  {
    id: "modelo-37",
    name: "polanco",
    description: "Lime Green Satin Halter neck Backless Maxi dress",
    price: 70,
    type: "maxi dress",
    tags: ["daytime", "cocktail", "beach"],
    material: "satin",
    size: "medium",
    color: "green",
    images: ["polanco-1.jpg", "polanco-2.jpg", "polanco-main.jpg"],
  },
  {
    id: "modelo-100",
    name: "reforma",
    description: "Blue Satin One shoulder Draped Side Maxi dress",
    price: 70,
    type: "maxi dress",
    tags: ["daytime", "cocktail", "beach", "evening"],
    material: "satin",
    size: "medium",
    color: "blue",
    images: ["reforma-main.jpg", "reforma-2.jpg", "reforma-1.jpg"],
  },
  {
    id: "modelo-31",
    name: "yalitza",
    description:
      "Lilac Floral Print Satin Cutout Long sleeves Back Knotted Maxi dress",
    price: 70,
    type: "maxi dress",
    tags: ["daytime", "cocktail"],
    material: "satin",
    size: "medium",
    color: "lilac",
    images: ["yalitza-main.jpg", "yalitza-1.jpg", "yalitza-2.jpg"],
  },
  {
    id: "modelo-32",
    name: "vervena",
    description: "White Pink Floral Print Cutout Long sleeves Maxi dress",
    price: 70,
    type: "maxi dress",
    tags: ["daytime", "cocktail"],
    material: "satin",
    size: "medium",
    color: "pink",
    images: ["vervena-main.jpg", "vervena-1.jpg", "vervena-2.jpg"],
  },
  {
    id: "modelo-123",
    name: "oaxaca",
    description: "Blue Floral print Front bow Long sleeves Maxi dress",
    price: 70,
    type: "maxi dress",
    tags: ["daytime", "cocktail", "beach", "evening"],
    material: "satin",
    size: "medium",
    images: ["oaxaca-main.jpg", "oaxaca-2.jpg", "oaxaca-1.jpg"],
  },
  {
    id: "modelo-10",
    name: "riviera",
    description: "Emerald green Satin V-neck Long sleeves Maxi dress",
    price: 70,
    type: "maxi dress",
    tags: ["daytime", "cocktail", "evening"],
    material: "satin",
    size: "medium",
    color: "green",
    images: ["riviera-1.jpg", "riviera-2.jpg", "riviera-main.jpg"],
  },
  {
    id: "modelo-70",
    name: "cancun",
    description: "Hot pink Satin Cowl Neck Maxi dress",
    price: 70,
    type: "maxi dress",
    tags: ["daytime", "cocktail", "beach"],
    material: "satin",
    size: "medium",
    color: "pink",
    images: ["cancun-2.jpg", "cancun-3.jpg", "cancun-1.jpg", "cancun-main.jpg"],
  },
  {
    id: "modelo-128",
    name: "angela",
    description: "Lilac lycra Cutout One shoulder Long Sleeve Maxi dress",
    price: 70,
    type: "maxi dress",
    tags: ["daytime", "cocktail"],
    material: "lycra",
    size: "medium",
    color: "lilac",
    images: ["angela-main.jpg", "angela-1.jpg", "angela-2.jpg"],
  },
  {
    id: "modelo-84",
    name: "cerezo",
    description:
      "Cherry Red lycra Plunge neck Long Sleeves Draped Side Maxi dress",
    price: 70,
    type: "maxi dress",
    tags: ["daytime", "cocktail", "evening"],
    material: "lycra",
    size: "medium",
    color: "red",
    images: ["cerezo-1.jpg", "cerezo-2.jpg", "cerezo-main.jpg"],
  },
  {
    id: "modelo-103",
    name: "eliza",
    description: "Black Velvet One shoulder Long Sleeve Cutout Maxi dress",
    price: 70,
    type: "maxi dress",
    tags: ["evening"],
    material: "velvet",
    size: "medium",
    color: "black",
    images: ["eliza-2.jpg", "eliza-1.jpg", "eliza-main.jpg"],
  },
  {
    id: "modelo-119",
    name: "frida",
    description: "Pink Green Tropical print One Shoulder Flounce Maxi dress",
    price: 70,
    type: "maxi dress",
    tags: ["daytime", "cocktail", "beach", "evening"],
    material: "",
    size: "medium",
    color: "green",
    images: ["frida-main.jpg", "frida-2.jpg", "frida-1.jpg"],
  },
  {
    id: "modelo-23",
    name: "cozumel",
    description: "Deep Blue Velvet Cutout One shoulder Bow Maxi dress",
    price: 70,
    type: "maxi dress",
    tags: ["evening"],
    material: "velvet",
    size: "medium",
    color: "blue",
    images: ["cozumel-main.jpg", "cozumel-1.jpg", "cozumel-2.jpg"],
  },
  {
    id: "modelo-125",
    name: "margarita",
    description: "Hot Pink Shiffon V-neck Sleeveless Flounce Maxi dress",
    price: 70,
    type: "maxi dress",
    tags: ["daytime", "cocktail", "beach"],
    material: "shiffon",
    size: "medium",
    color: "pink",
    images: ["margarita-main.jpg", "margarita-2.jpg", "margarita-1.jpg"],
  },
  {
    id: "modelo-72",
    name: "jazmin",
    description: "White Satin Draped side Midi Dress",
    price: 60,
    type: "midi dress",
    tags: ["daytime", "cocktail", "beach"],
    material: "satin",
    size: "medium",
    color: "white",
    images: ["jazmin-2.jpg", "jazmin-3.jpg", "jazmin-1.jpg", "jazmin-main.jpg"],
  },
  {
    id: "modelo-107",
    name: "acapulco",
    description:
      "Lime green Shiffon Plunge neck Long Sleeves Flounce Open back Maxi dress",
    price: 70,
    type: "maxi dress",
    tags: ["daytime", "cocktail"],
    material: "shiffon",
    size: "medium",
    color: "green",
    images: [
      ".DS_Store",
      "acapulco-main.jpg",
      "acapulco-2.jpg",
      "acapulco-1.jpg",
    ],
  },
  {
    id: "modelo-113",
    name: "magnolia",
    description: "Hot Pink Jacquard Cutout Mini dress",
    price: 50,
    type: "mini dress",
    tags: ["daytime", "cocktail", "beach"],
    material: "",
    size: "medium",
    color: "pink",
    images: [
      "magnolia-3.jpg",
      "magnolia-2.jpg",
      "magnolia-main.jpg",
      "magnolia-1.jpg",
    ],
  },
  {
    id: "modelo-110",
    name: "camelia",
    description: "Red Lace Long Sleeves Flounce Mini Dress",
    price: 50,
    type: "mini dress",
    tags: ["daytime", "cocktail"],
    material: "lace",
    size: "medium",
    color: "red",
    images: [
      "camelia-main.jpg",
      "camelia-2.jpg",
      "camelia-3.jpg",
      "camelia-1.jpg",
    ],
  },
  {
    id: "modelo-24",
    name: "ofelia",
    description:
      "Blue Floral print Plunge neck Long sleeves Open back Maxi dress",
    price: 70,
    type: "maxi dress",
    tags: ["daytime", "cocktail", "beach", "evening"],
    material: "satin",
    size: "medium",
    color: "blue",
    images: ["ofelia-main.jpg", "ofelia-2.jpg", "ofelia-3.jpg", "ofelia-1.jpg"],
  },
  {
    id: "modelo-71",
    name: "san angel",
    description: "Black Cutout shoulder Midi dress",
    price: 60,
    type: "midi dress",
    tags: ["evening"],
    material: "",
    size: "medium",
    color: "black",
    images: ["sanangel-1.jpg", "sanangel-2.jpg", "sanangel-main.jpg"],
  },
  {
    id: "modelo-33",
    name: "narciso",
    description: "Yellow Cutout Shoulder bows Flounce bottom Midi dress",
    price: 60,
    type: "midi dress",
    tags: ["daytime", "cocktail", "beach"],
    material: "",
    size: "medium",
    color: "yellow",
    images: ["narciso-2.jpg", "narciso-1.jpg", "narciso-main.jpg"],
  },
  {
    id: "modelo-26",
    name: "girasol",
    description: "Yellow Shiffon and Lace Flounce Maxi dress",
    price: 70,
    type: "maxi dress",
    tags: ["daytime", "cocktail", "beach"],
    material: "shiffon",
    size: "medium",
    color: "yellow",
    images: ["girasol-1.jpg", "girasol-2.jpg", "girasol-main.jpg"],
  },
  {
    id: "modelo-63",
    name: "hidalgo",
    description: "Coral Sleveless Tie-front Maxi dress",
    price: 70,
    type: "maxi dress",
    tags: ["daytime", "cocktail", "beach"],
    material: "",
    size: "medium",
    color: "orange",
    images: ["hidalgo-2.jpg", "hidalgo-1.jpg", "hidalgo-main.jpg"],
  },
  {
    id: "modelo-55",
    name: "san miguel",
    description:
      "Pink Floral print Plunge neck Cutout Open back Long sleeves Maxi Dress",
    price: 70,
    type: "maxi dress",
    tags: ["daytime", "cocktail", "beach"],
    material: "",
    size: "medium",
    color: "pink",
    images: ["sanmiguel-2.jpg", "sanmiguel-1.jpg", "sanmiguel-main.jpg"],
  },
  {
    id: "modelo-13",
    name: "mariachi",
    description:
      "Black Lace and Tulle V-neck Sleeveless Sheer Lace skirt Maxi dress",
    price: 70,
    type: "maxi dress",
    tags: ["evening"],
    material: "lace",
    size: "medium",
    color: "black",
    images: [
      "mariachi-2.jpg",
      "mariachi-3.jpg",
      "mariachi-1.jpg",
      "mariachi-4.jpg",
      "mariachi-main.jpg",
    ],
  },
  {
    id: "modelo-93",
    name: "silvia",
    description:
      "White Tropical Blue print One Shoulder flounce Draped front Jumpsuite",
    price: 60,
    type: "jumpsuit",
    tags: ["daytime", "cocktail"],
    material: "",
    size: "medium",
    color: "white",
    images: ["silvia-main.jpg", "silvia-2.jpg", "silvia-3.jpg", "silvia-1.jpg"],
  },
  {
    id: "modelo-96",
    name: "sofia",
    description:
      "Mint green V-neck Bows on shoulders Flounce bottom Midi dress",
    price: 60,
    type: "midi dress",
    tags: ["daytime", "cocktail"],
    material: "",
    size: "medium",
    color: "green",
    images: ["sofia-3.jpg", "sofia-main.jpg", "sofia-2.jpg", "sofia-1.jpg"],
  },
  {
    id: "modelo-83",
    name: "rosaura",
    description: "Baby Pink Satin Halter neck Backless Flounce Midi Dress",
    price: 60,
    type: "midi dress",
    tags: ["daytime", "cocktail", "beach"],
    material: "satin",
    size: "medium",
    color: "pink",
    images: ["rosaura-1.jpg", "rosaura-2.jpg", "rosaura-main.jpg"],
  },
  {
    id: "modelo-126",
    name: "natalia",
    description: "Lilac Lycra Off-shoulder Midi dress",
    price: 50,
    type: "midi dress",
    tags: ["daytime", "cocktail", "beach", "evening"],
    material: "lycra",
    size: "medium",
    color: "lilac",
    images: ["natalia-1.jpg", "natalia-main.jpg"],
  },
  {
    id: "modelo-17",
    name: "amari",
    description: "Red Lycra Off-shoulder Flounce Midi dress",
    price: 50,
    type: "midi dress",
    tags: ["daytime", "cocktail", "beach", "evening"],
    material: "lycra",
    size: "medium",
    color: "red",
    images: ["amari-2.jpg", "amari-3.jpg", "amari-1.jpg", "amari-main.jpg"],
  },
  {
    id: "modelo-200",
    name: "chiapas",
    description: "Blue Lace Tulle Off Shoulder Wide Sleeves Flounce Mini dress",
    price: 50,
    type: "mini dress",
    tags: ["daytime", "beach"],
    material: "shiffon",
    size: "medium",
    color: "blue",
    images: [
      "chiapas-main.jpg",
      "chiapas-1.jpg",
      "chiapas-3.jpg",
      "chiapas-2.jpg",
    ],
  },
  {
    id: "modelo-203",
    name: "luz",
    description: "Magenta Glitter Cutout back Maxi dress",
    price: 70,
    type: "maxi dress",
    tags: ["evening", "formal"],
    material: "glitter",
    size: "medium",
    color: "purple",
    images: ["luz-main.jpg", "luz-1.jpg", "luz-2.jpg"],
  },
  {
    id: "modelo-204",
    name: "madre selva",
    description: "Orange Red Lycra Backless Long Sleeves Maxi dress",
    price: 70,
    type: "maxi dress",
    tags: ["daytime", "cocktail", "beach", "evening"],
    material: "lycra",
    size: "medium",
    color: "orange",
    images: ["madreselva-2.jpg", "madreselva-1.jpg", "madreselva-main.jpg"],
  },
  {
    id: "modelo-205",
    name: "jacaranda",
    description: "Magenta Lycra Transformer/Wrap Maxi dress",
    price: 70,
    type: "maxi dress",
    tags: ["daytime", "cocktail", "beach", "evening"],
    material: "lycra",
    size: "medium",
    color: "purple",
    images: [
      "jacaranda-main.jpg",
      "jacaranda-6.jpg",
      "jacaranda-4.jpg",
      "jacaranda-5.jpg",
      "jacaranda-1.jpg",
      "jacaranda-2.jpg",
      "jacaranda-3.jpg",
    ],
  },
  {
    id: "modelo-208",
    name: "campeche",
    description: "Pink blush Halter neck Backless Jumpsuit",
    price: 55,
    type: "jumpsuit",
    tags: ["daytime"],
    material: "",
    size: "medium",
    color: "pink",
    images: [
      "campeche-main.jpg",
      "campeche-4.jpg",
      "campeche-3.jpg",
      "campeche-2.jpg",
      "campeche-1.jpg",
    ],
  },
  {
    id: "modelo-209",
    name: "rosalinda",
    description: "Deep Blue Lace Bustier Maxi dress",
    price: 70,
    type: "maxi dress",
    tags: ["evening"],
    material: "lace",
    size: "medium",
    color: "blue",
    images: ["rosalinda-catalog.jpg"],
  },
  {
    id: "modelo-210",
    name: "garibaldi",
    description: "Silver Sequins Long Sleeves Romper",
    price: 50,
    type: "romper",
    tags: ["evening"],
    material: "sequins",
    size: "medium",
    color: "silver",
    images: ["garibaldi-catalog.jpg"],
  },
  {
    id: "modelo-59",
    name: "carmen",
    description: "Yellow Satin Long sleeves V-neck Wrap Maxi dress",
    price: 70,
    type: "maxi dress",
    tags: ["daytime", "cocktail"],
    material: "satin",
    size: "large",
    color: "yellow",
    images: ["carmen-main.jpg", "carmen-2.jpg", "carmen-1.jpg"],
  },
  {
    id: "modelo-120",
    name: "gardenia",
    description: "Baby Blue Satin Flower print Long Sleeves Cutout Maxi dress",
    price: 70,
    type: "maxi dress",
    tags: ["daytime", "cocktail", "beach", "evening"],
    material: "satin",
    size: "large",
    color: "blue",
    images: ["gardenia-1.jpg", "gardenia-main.jpg", "gardenia-2.jpg"],
  },
  {
    id: "modelo-116",
    name: "bacalar",
    description: "Blue Print Satin One shoulder Maxi dress",
    price: 70,
    type: "maxi dress",
    tags: ["daytime", "cocktail", "beach"],
    material: "satin",
    size: "large",
    color: "blue",
    images: ["bacalar-main.jpg", "bacalar-1.jpg", "bacalar-2.jpg"],
  },
  {
    id: "modelo-129",
    name: "monterrey",
    description: "Black Lycra V-neck Flounce shoulder and side Maxi dress",
    price: 70,
    type: "maxi dress",
    tags: ["evening"],
    material: "lycra",
    size: "large",
    color: "black",
    images: [
      "monterrey-main.jpg",
      "monterrey-3.jpg",
      "monterrey-2.jpg",
      "monterrey-1.jpg",
    ],
  },
  {
    id: "modelo-29",
    name: "orquidea",
    description:
      "Pink Tropical print V-neck Cutout Criss Cross backless Puff sleve Maxi dress",
    price: 70,
    type: "maxi dress",
    tags: ["daytime"],
    material: "",
    size: "large",
    color: "pink",
    images: [
      "orquidea-main.jpg",
      "orquidea-1.jpg",
      "orquidea-3.jpg",
      "orquidea-2.jpg",
    ],
  },
  {
    id: "modelo-108",
    name: "estela",
    description:
      "White V-neck Long sleeves Drapped side Flounce Backless Mini dress",
    price: 50,
    type: "mini dress",
    tags: ["daytime", "cocktail"],
    material: "",
    size: "large",
    color: "white",
    images: ["estela-main.jpg", "estela-1.jpg"],
  },
  {
    id: "modelo-202",
    name: "rita",
    description: "Blue One shoulder Sleeveles Flounce Midi dress",
    price: 60,
    type: "midi dress",
    tags: ["daytime", "cocktail"],
    material: "",
    size: "large",
    color: "blue",
    images: ["rita-1.jpg", "rita-3.jpg", "rita-2.jpg", "rita-main.jpg"],
  },
  {
    id: "modelo-50",
    name: "soria",
    description: "Black Satin One Sholder Draped side Maxi dress",
    price: 70,
    type: "maxi dress",
    tags: ["evening"],
    material: "satin",
    size: "extra large",
    color: "black",
    images: ["soria-catalog.jpg"],
  },
  {
    id: "modelo-115",
    name: "rosario",
    description:
      "Olive Green Tropical print V-neck Long Puff sleeves Flounce Maxi dress",
    price: 70,
    type: "maxi dress",
    tags: ["daytime", "cocktail", "beach", "evening"],
    material: "satin",
    size: "extra large",
    color: "green",
    images: ["rosario-catalog.jpg"],
  },
  {
    id: "modelo-45",
    name: "india",
    description: "Pink Shiffon Sleeves Wrap Flounce Maxi dress",
    price: 70,
    type: "maxi dress",
    tags: ["daytime", "cocktail", "beach"],
    material: "shiffon",
    size: "extra large",
    color: "pink",
    images: ["india-catalog.jpg"],
  },
];

export default data;
