import { useState } from "react";
import Filter from "./components/Filter";
import Options from "./components/Options";
import ColorOptions from "./components/ColorOptions";
import "./styles.css";
import data from "../data.js";
import filterIcon from "./filter.png";
import closeIcon from "./close.png";

export default function Filters({ selectedFilters, setSelectedFilters }) {
  const [currentFilter, setCurrentFilter] = useState("");
  const [filterModalOpen, setFilterModalOpen] = useState(false);

  const handleFilter = (filter) => {
    setCurrentFilter(filter);
  };

  const handleOption = (tag, option) => {
    setSelectedFilters({
      ...selectedFilters,
      [tag]: selectedFilters[tag] !== option ? option : "",
    });
  };

  const getSizeOptions = () => {
    const sizes = data.map((dress) => dress.size).filter((n) => n);
    return [...new Set(sizes)];
  };

  const getMaterialOptions = () => {
    const materials = data.map((dress) => dress.material).filter((n) => n);
    return [...new Set(materials)];
  };

  const getColorOptions = () => {
    const colors = data.map((dress) => dress.color).filter((n) => n);
    return [...new Set(colors)];
  };

  const getOccasionOptions = () => {
    const occasions = data.map((dress) => dress.tags);
    return [...new Set(occasions.flat())];
  };

  const getTypeOptions = () => {
    const types = data.map((dress) => dress.type).filter((n) => n);
    return [...new Set(types)];
  };

  const renderCurrentFilter = () => {
    const filters = {
      Size: (
        <Options
          tag="size"
          options={getSizeOptions()}
          handleOption={handleOption}
          selected={selectedFilters}
        />
      ),
      Material: (
        <Options
          tag="material"
          options={getMaterialOptions()}
          handleOption={handleOption}
          selected={selectedFilters}
        />
      ),
      Color: (
        <ColorOptions
          tag="color"
          options={getColorOptions()}
          handleOption={handleOption}
          selected={selectedFilters}
        />
      ),
      Occasion: (
        <Options
          tag="occasion"
          options={getOccasionOptions()}
          handleOption={handleOption}
          selected={selectedFilters}
        />
      ),
      Type: (
        <Options
          tag="type"
          options={getTypeOptions()}
          handleOption={handleOption}
          selected={selectedFilters}
        />
      ),
    };
    return filters[currentFilter] || <></>;
  };

  const renderSelectedFilters = () => {
    const selected = [];

    Object.keys(selectedFilters).forEach((key) => {
      if (selectedFilters[key]) {
        selected.push(
          <button
            key={key}
            className="ClearFilters"
            onClick={() => handleOption(key, selectedFilters[key])}
          >
            {selectedFilters[key]}
          </button>
        );
      }
    });
    return selected;
  };

  const filtersAreSelected = () => {
    return Object.keys(selectedFilters).some(
      (key) => selectedFilters[key] !== ""
    );
  };

  const renderFilters = () => {
    return (
      <div className="FiltersContainer">
        <Filter tag="Color" onSelect={handleFilter} />
        <Filter tag="Size" onSelect={handleFilter} />
        <Filter tag="Material" onSelect={handleFilter} />
        <Filter tag="Occasion" onSelect={handleFilter} />
        <Filter tag="Type" onSelect={handleFilter} />
      </div>
    );
  };

  const renderClearOptions = () => {
    return (
      filtersAreSelected() && (
        <div className="FilteredBy">
          {renderSelectedFilters()}
          <button
            className="ClearFilters"
            onClick={() => {
              setSelectedFilters({
                size: "",
                material: "",
                color: "",
                occasion: "",
              });
            }}
          >
            Clear All
          </button>
        </div>
      )
    );
  };

  return (
    <>
      <div className="FiltersMain">
        {renderFilters()}
        {renderCurrentFilter()}
        {renderClearOptions()}
      </div>
      <div className="FiltersMainMobile">
        <img
          src={filterIcon}
          alt="Filter"
          className="FilterIcon"
          onClick={() => setFilterModalOpen(true)}
        />
        {filterModalOpen && (
          <>
            <div
              className="MobileFilterMask"
              onClick={() => setFilterModalOpen(false)}
            />
            <div className="MobileFilterModal">
              <div className="MobileFilterHeader">
                <img
                  src={closeIcon}
                  alt="Filter"
                  className="CloseIcon"
                  onClick={() => setFilterModalOpen(false)}
                />
                <p>Filters</p>
              </div>
              {renderFilters()}
              {renderCurrentFilter()}
              {renderClearOptions()}
            </div>
          </>
        )}
      </div>
    </>
  );
}
