import Carousel from "react-gallery-carousel";
import "react-gallery-carousel/dist/index.css";
import "./styles.css";

export default function Modal({ onClose, images, name }) {
  const carouselImages = images.map((image) => {
    const nameReplace = name.replace(" ", "");
    return {
      src: `${process.env.PUBLIC_URL}/dresses/${nameReplace}/${image}`,
    };
  });

  return (
    <div className="ModalImage">
      <div className="ModalImageMask" onClick={onClose}></div>
      <div className="ModalImageElement">
        <Carousel
          hasSizeButton={false}
          images={carouselImages}
          canAutoPlay={false}
          objectFit="contain"
          className="CarouselComponent"
        />
      </div>
    </div>
  );
}
