import logo from "./logo.png";
import instagram from "./instagram.png";
import map from "./map.png";
import "./styles.css";

export default function Header() {
  return (
    <header>
      <div className="HeaderContainer">
        <div className="Links">
          <img src={logo} alt="Estilo Santa Cruz" />
          <p>Dress Rentals Miami</p>
        </div>
        <div className="Contact">
          <p>Coral Way, Miami</p>
          <a
            href="https://www.instagram.com/estilo.santa.cruz"
            rel="noreferrer"
            target="_blank"
          >
            <img src={instagram} alt="Instagram" />
          </a>
          <a
            href="https://maps.app.goo.gl/UVzmchi1fhPXt5a9A"
            rel="noreferrer"
            target="_blank"
          >
            <img src={map} alt="Map" />
          </a>
        </div>
      </div>
    </header>
  );
}
