import { useState } from "react";
import Modal from "./Modal";

import "./styles.css";

export default function Dress({ name, size, price, description, images }) {
  const [showModal, setShowModal] = useState(false);

  const getImgSource = (name) => {
    const removeSpaces = name.replace(/\s/g, "");
    return `url(${process.env.PUBLIC_URL}/dresses/${removeSpaces}/${removeSpaces}-catalog.jpg)`;
  };

  const handleClick = () => {
    setShowModal(true);
  };

  return (
    <>
      {showModal && (
        <Modal
          onClose={() => setShowModal(false)}
          images={images}
          name={name}
        />
      )}
      <li className="DressElement" onClick={handleClick}>
        <div
          className="Image"
          style={{
            backgroundImage: getImgSource(name),
          }}
        >
          <p className="Size">{size}</p>
        </div>

        <div className="Metadata">
          <p className="Name">{name}</p>
          <p className="Price">$ {price}</p>
          <p className="Description">{description}</p>
        </div>
      </li>
    </>
  );
}
