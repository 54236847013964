import { useEffect, useState } from "react";
import Filters from "./Filters";
import DressesList from "./DressesList";
import Header from "./Header";
import "./App.css";

import data from "./data.js";

function shuffle(a) {
  var j, x, i;
  for (i = a.length - 1; i > 0; i--) {
    j = Math.floor(Math.random() * (i + 1));
    x = a[i];
    a[i] = a[j];
    a[j] = x;
  }
  return a;
}

const shuffledData = shuffle(data);

function App() {
  const [dresses, setDresses] = useState(shuffledData);

  const [selectedFilters, setSelectedFilters] = useState({
    size: "",
    material: "",
    color: "",
  });

  const filterBySize = (dress) => {
    if (!selectedFilters.size) return true;
    return selectedFilters.size === dress.size;
  };

  const filterByMaterial = (dress) => {
    if (!selectedFilters.material) return true;
    return selectedFilters.material === dress.material;
  };

  const filterByColor = (dress) => {
    if (!selectedFilters.color) return true;
    return selectedFilters.color === dress.color;
  };

  const filterByOccasion = (dress) => {
    if (!selectedFilters.occasion) return true;
    return dress.tags.includes(selectedFilters.occasion);
  };

  const filterByType = (dress) => {
    if (!selectedFilters.type) return true;
    return selectedFilters.type === dress.type;
  };

  useEffect(() => {
    const filteredDresses = data.filter((dress) => {
      return (
        filterBySize(dress) &&
        filterByMaterial(dress) &&
        filterByColor(dress) &&
        filterByOccasion(dress) &&
        filterByType(dress)
      );
    });
    setDresses(filteredDresses);
  }, [selectedFilters]);

  return (
    <>
      <Header />
      <main className="MainConatiner">
        <div>
          <Filters {...{ selectedFilters, setSelectedFilters }} />
          <DressesList dresses={dresses} />
        </div>
      </main>
    </>
  );
}

export default App;
