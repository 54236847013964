import "./styles.css";

export default function Options({ options, handleOption, selected, tag }) {
  return (
    <div className="OptionsContainer">
      {options.map((option) => {
        return (
          <button
            className="OptionElement"
            onClick={() => handleOption(tag, option)}
            key={option}
            style={{
              backgroundColor: selected[tag] === option ? "#f2f2f2" : "",
            }}
          >
            {option}
          </button>
        );
      })}
    </div>
  );
}
