import "./styles.css";

const hexColors = {
  black: "#000000",
  silver: "#C0C0C0",
  red: "#FF0000",
  blue: "#0000FF",
  lilac: "#C8A2C8",
  pink: "#FFC0CB",
  green: "#008000",
  white: "#FFFFFF",
  yellow: "#FFFF00",
  orange: "#FFA500",
  purple: "#800080",
};

export default function Options({ options, handleOption, selected, tag }) {
  return (
    <div className="OptionsContainer">
      {options.map((option) => {
        return (
          <button
            className={`ColorOptionElement ${
              selected[tag].includes(option) ? "ColorOptionElementSelected" : ""
            }`}
            onClick={() => handleOption(tag, option)}
            key={option}
            style={{
              backgroundColor: hexColors[option.replace(" ", "").toLowerCase()],
            }}
            title={option}
          ></button>
        );
      })}
    </div>
  );
}
